<template>
    <div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2" v-if="selectedEntity === 'person'">
                <span class="text-light">Complete Name</span>
            </div>
            <div class="tw-w-1/4 tw-py-2" v-else>
                <span class="text-light">Organization Name</span>
            </div>
            <div v-if="addressData.complete_details.identity.completename !== '' || addressData.complete_details.identity.organization_name !== ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> {{ addressData.complete_details.identity.completename }} {{ addressData.complete_details.identity.organization_name }} </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3"> - </span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">House Number</span>
            </div>
            <div v-if="addressData.complete_details.address.houseNumber == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2 tw-flex tw-flex-col">
                <span class="tw-pl-3">{{ addressData.complete_details.address.houseNumber }}</span>
                <!-- <span v-if="addressData.complete_details.address.messages != []" class="tw-pl-3 text-light ">{{(addressData.complete_details.address.messages)}}</span> -->
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Thoroughfare</span>
            </div>
            <div v-if="addressData.complete_details.address.thoroughfare == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.thoroughfare }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Postal Code</span>
            </div>
            <div v-if="addressData.complete_details.address.postalCode == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.postalCode }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Locality</span>
            </div>
            <div v-if="addressData.complete_details.address.locality == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.locality }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Country Name</span>
            </div>
            <div v-if="addressData.complete_details.address.countryName == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.countryName }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Formal Country Name</span>
            </div>
            <div v-if="addressData.complete_details.address.formalCountryName == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.formalCountryName }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Country Code</span>
            </div>
            <div v-if="addressData.complete_details.address.countryCode == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.countryCode }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Country Number</span>
            </div>
            <div v-if="addressData.complete_details.address.countryNumber == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.countryNumber }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Country ISO3</span>
            </div>
            <div v-if="addressData.complete_details.address.countryISO3 == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.countryISO3 }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Adddress Line 1</span>
            </div>
            <div v-if="addressData.complete_details.address.addressLine1 == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.addressLine1 }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Adddress Line 2</span>
            </div>
            <div v-if="addressData.complete_details.address.addressLine2 == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ addressData.complete_details.address.addressLine2 }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "address",

    props: {
        addressData: {
            type: Array,
            default: () => [],
        },
        selectedEntity: {
            type: String,
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
<style>
.text-light {
    color: rgb(148 163 184);
}
</style>

<template>
    <div class="tw-relative tw-overflow-auto scroll-bar tw-h-40">
        <table class="tw-w-full tw-text-sm tw-text-left tw-table-fixed tw-border-collapse tw-border tw-border-gray-700">
            <thead class="tw-bg-gray-100 tw-py-2 tw-sticky tw-text-gray-500 tw-text-sm tw-top-0 tw-border-collapse tw-border tw-border-slate-300">
                <tr class="tw-border-collapse tw-border tw-border-gray-700">
                    <th scope="col" class="tw-px-4 tw-py-2 tw-w-1/4 tw-border-collapse tw-border tw-border-gray-700" v-for="cell in column" :key="cell.value">
                        {{ cell.label }}
                    </th>
                </tr>
            </thead>
            <tbody class="">
                <tr class="tw-bg-white tw-border-collapse tw-border tw-border-gray-700 tw-text-left" v-for="(code, index) in result" :key="index">
                    <td v-if="code.matchType.toLowerCase() !== 'codes' && code.matchType.toLowerCase() !== 'wv'" class="tw-border-collapse tw-border tw-border-gray-700 tw-px-4 tw-py-2">
                        {{ matchType[code.matchType] ? matchType[code.matchType] : code.matchType }}
                    </td>
                    <td class="tw-border-collapse tw-border tw-border-gray-700 tw-px-4 tw-py-2">{{ sourceType[code.sourceType] ? sourceType[code.sourceType] : code.sourceType }}</td>
                    <td v-if="code.field.toLowerCase() !== 'codes' && code.field.toLowerCase() !== 'wv'" class="tw-border-collapse tw-border tw-border-gray-700 tw-px-4 tw-py-2">
                        {{ fieldType[code.field] ? fieldType[code.field] : code.field }}
                    </td>
                    <td v-if="code.matchType.toLowerCase() !== 'codes' && code.matchType.toLowerCase() !== 'wv'" class="tw-border-collapse tw-border tw-border-gray-700 tw-px-4 tw-py-2">
                        {{ code.message }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Constant from "./constant.json";
export default {
    name: "email",

    props: {
        detailsData: {
            type: Object,
            default: () => [],
        },
    },
    data() {
        return {
            column: [
                {
                    label: "Match Type",
                    value: "matchType",
                },
                {
                    label: "Source Type",
                    value: "sourceType",
                },
                {
                    label: "Field",
                    value: "field",
                },
                {
                    label: "Description",
                    value: "description",
                },
            ],
            result: [],
        };
    },
    computed: {
        fieldType() {
            return Constant.fieldTypes;
        },
        matchType() {
            return Constant.matchTypes;
        },
        sourceType() {
            return Constant.sourceTypes;
        },
    },
    mounted() {
        this.result = [];
        if (this.detailsData.complete_details.identity.codes.messages.length) {
            this.detailsData.complete_details.identity.codes.messages.map((el) => {
                if (el.code) {
                    let a = el.code.split("-");
                    let fieldDetails = el.code.split("-");
                    fieldDetails.shift();
                    fieldDetails.pop();
                    let array = fieldDetails.join("-");
                    let info;
                    info = {
                        matchType: a[0],
                        sourceType: array,
                        field: a[a.length - 1],
                        message: el.value,
                    };
                    this.result.push(info);
                }
            });
        }
        if (this.detailsData.complete_details.address.codes.messages.length) {
            this.detailsData.complete_details.address.codes.messages.map((el) => {
                if (el.code) {
                    let a = el.code.split("-");
                    let fieldDetails = el.code.split("-");
                    fieldDetails.shift();
                    fieldDetails.pop();
                    let array = fieldDetails.join(" ");
                    let info;
                    info = {
                        matchType: a[0],
                        sourceType: array,
                        field: a[a.length - 1],
                    };
                    this.result.push(info);
                }
            });
        }
        if (this.detailsData.complete_details.email.codes.messages.length) {
            this.detailsData.complete_details.email.codes.messages.map((el) => {
                if (el.code) {
                    let a = el.code.split("-");
                    let fieldDetails = el.code.split("-");
                    fieldDetails.shift();
                    fieldDetails.pop();
                    let array = fieldDetails.join(" ");
                    let info;
                    info = {
                        matchType: a[0],
                        sourceType: array,
                        field: a[a.length - 1],
                    };
                    this.result.push(info);
                }
            });
        }
        if (this.detailsData.complete_details.phone.codes.messages.length) {
            this.detailsData.complete_details.phone.codes.messages.map((el) => {
                if (el.code) {
                    let a = el.code.split("-");
                    let fieldDetails = el.code.split("-");
                    fieldDetails.shift();
                    fieldDetails.pop();
                    let array = fieldDetails.join(" ");
                    let info;
                    info = {
                        matchType: a[0],
                        sourceType: array,
                        field: a[a.length - 1],
                    };
                    this.result.push(info);
                }
            });
        }
    },
    methods: {},
};
</script>
<style>
.text-light {
    color: rgb(148 163 184);
}
</style>

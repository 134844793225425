<template>
    <div class="tw-mb-2">
        <div class="headingBlock print-d-block tw-pt-3 tw-px-3 tw-mt-4 tw-border tw-border-solid tw-rounded-md" :class="getStyleClass" @click="handleToggle(item, item.dataExist)">
            <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-pb-3 border-bottom">
                <div class="tw-text-md tw-font-medium" :class="getItemStyle">
                    {{ item.label }}
                </div>
                <div class="tw-flex tw-items-center" v-if="item.dataExist">
                    <div v-if="item.label != 'Details'" class="tw-text-md tw-font-normal">
                        Reliability Score :
                        <span class="tw-text-md tw-capitalize tw-pl-2" style="word-break: break-word" :class="getStatusColor(item.reliability)">
                            <span class="capitalize text-green-400">{{ getreliabity(item.reliability) }}</span>
                        </span>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" class="tw-h-6 tw-w-6 tw-cursor-pointer tw-transition-all tw-transform tw-duration-300 hover:tw-text-brand" fill="none" viewBox="0 0 24 24" stroke="currentColor" :class="{'tw-rotate-180': showItemDetail}">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                </div>
            </div>
            <div class="tw-p-2 headingBlock-sibling" v-if="showItemDetail && item.dataExist">
                <slot :name="item.value"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "expand",
    components: {},
    props: {
        item: {
            type: Object,
            default: () => {},
        },
        expandAll: {
            type: Boolean,
            default: false,
        },
        cardDetails: {
            type: Object,
            default: () => [],
        },
    },
    data() {
        return {
            showItemDetail: false,
        };
    },
    watch: {
        expandAll: function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.showItemDetail = newVal;
            }
        },
    },
    computed: {
        getStyleClass() {
            return this.item.dataExist ? "tw-bg-white-50 tw-border-grey-200 tw-cursor-pointer hover:tw-shadow-md" : "tw-bg-gray-100 tw-opacity-80 tw-cursor-not-allowed";
        },
        getItemStyle() {
            return this.item.dataExist ? "tw-text-blue-500" : "tw-text-gray-600";
        },
    },
    methods: {
        handleToggle(item, dataExist) {
            if (dataExist) {
                this.showItemDetail = !this.showItemDetail;
                this.$emit("handleExpandAll", item.value, this.showItemDetail);
            } else {
                return;
            }
        },
        getStatusColor(status) {
            if (status === "20") {
                return "tw-text-yellow-500";
            }
            if (status === "30") {
                return "tw-text-red-500";
            }
            if (status === "10") {
                return "tw-text-green-500";
            }
        },
        getreliabity(reliability) {
            if (reliability === "20") {
                return "Medium";
            }
            if (reliability === "30") {
                return "Low";
            }
            if (reliability === "10") {
                return "High";
            }
        },
    },
};
</script>
<style>
.border-bottom {
    border-bottom: 1px solid rgb(226 232 240);
}
</style>

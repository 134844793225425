<template>
    <div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2" v-if="selectedEntity === 'person'">
                <span class="text-light">Complete Name</span>
            </div>
            <div class="tw-w-1/4 tw-py-2" v-else>
                <span class="text-light">Organization Name</span>
            </div>
            <div v-if="identityData.complete_details.identity.completename !== '' || identityData.complete_details.identity.organization_name !== ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-2"> {{ identityData.complete_details.identity.completename }} {{ identityData.complete_details.identity.organization_name }} </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3"> - </span>
            </div>
        </div>
        <div class="tw-flex" v-if="selectedEntity === 'person'">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">First Name</span>
            </div>
            <div v-if="identityData.complete_details.identity.givenfullname == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2 tw-flex tw-flex-col">
                <span class="tw-pl-3">{{ identityData.complete_details.identity.givenfullname }}</span>
                <!-- <span v-if="identityData.complete_details.identity.messages != []" class="tw-pl-3 text-light">{{identityData.complete_details.identity.messages}}</span> -->
            </div>
        </div>
        <!-- <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light ">Middle Name</span>
            </div>
            <div class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{identityData.complete_details.identity}}</span>
            </div>
        </div> -->
        <div class="tw-flex" v-if="selectedEntity === 'person'">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Surname</span>
            </div>
            <div v-if="identityData.complete_details.identity.surname_first == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ identityData.complete_details.identity.surname_first }}</span>
            </div>
        </div>
        <div class="tw-flex" v-if="selectedEntity === 'person'">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Date Of Birth</span>
            </div>
            <div v-if="identityData.complete_details.identity.dob == ''" class="tw-text-md tw-pl-2" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
            <div v-else class="tw-w-3/4 tw-py-2">
                <span class="tw-pl-3">{{ getDate(identityData.complete_details.identity.dob) }}</span>
            </div>
        </div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2" v-if="selectedEntity === 'person'">
                <span class="text-light">National Id</span>
            </div>
            <div class="tw-w-1/4 tw-py-2" v-if="selectedEntity === 'company'">
                <span class="text-light">Business Id</span>
            </div>
            <div class="tw-w-3/4 tw-py-2" v-if="identityData.complete_details.identity.nationalid !== '' || identityData.complete_details.identity.businessid !== ''" style="word-break: break-word">
                <span class="tw-pl-3">{{ identityData.complete_details.identity.nationalid }} {{ identityData.complete_details.identity.businessid }} </span>
            </div>
            <div v-else class="tw-text-md tw-pl-2">
                <span class="flex tw-px-2 tw-py-1"> - </span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "identity",

    props: {
        identityData: {
            type: Object,
            default: () => [],
        },
        selectedEntity: {
            type: String,
        },
    },
    data() {
        return {};
    },

    mounted() {},
    methods: {
        getDate: function(date) {
            return moment(date).format("DD/MM/YYYY");
        },
    },
};
</script>

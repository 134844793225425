<template>
    <div>
        <div class="tw-flex">
            <div class="tw-w-1/4 tw-py-2">
                <span class="text-light">Email Adddress</span>
            </div>
            <div v-if="emailData.complete_details.email.full_email_address == ''" class="tw-text-md tw-pl-2 tw-flex tw-flex-col" style="word-break: break-word">
                <span class="flex tw-px-2 tw-py-1"> - </span>
                <!-- <span class="tw-pl-3 text-light ">{{emailData.complete_details.email.messages}}</span> -->
            </div>
            <div v-else class="tw-w-3/4 tw-py-2 tw-flex tw-flex-col">
                <span class="tw-pl-3">{{ emailData.complete_details.email.full_email_address }}</span>
                <!-- <span class="tw-pl-3 text-light ">{{emailData.complete_details.email.messages}}</span> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "email",

    props: {
        emailData: {
            type: Object,
            default: () => [],
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
<style>
.text-light {
    color: rgb(148 163 184);
}
</style>

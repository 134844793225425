import AnalysisCard from "../ToolsCard.vue";
import NeoLoader from "@/components/loader";
import NeoInput from "@/components/input";
import NeoButton from "@/components/button";
import OsintAxios from "@/axios/osint.js";
import {email, maxLength} from "vuelidate/lib/validators";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
import "@/assets/css/multiselect.scss";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NeoAnalyse from "@/components/analyse";
import _ from "lodash";
import JsonCSV from "vue-json-csv";
import PersonListExpand from "@/components/result-expanded";
import PersonList from "@/components/result-list";
import toolsHelper from "@/mixins/toolsHelper";
import neoSwitch from "@/components/toggle-switch";
import {EventBus} from "@/main.js";
import {DateTime} from "luxon";
import AccordionCard from "@/components/accordion";
import DatePicker from "vue2-datepicker";
import DetailList from "./detailList.vue";
import Addrs from "./ListItemsComponents/adress.vue";
import Identity from "./ListItemsComponents/identity.vue";
import Email from "./ListItemsComponents/email.vue";
import Phone from "./ListItemsComponents/phone.vue";
import Details from "./ListItemsComponents/details.vue";

const generateFiltersWrapperModel = (title, src_name, value, filters, tagClass, disabled) => ({
    title,
    src_name,
    value,
    filters,
    tagClass,
    isChecked: false,
    isModalVisible: false,
    disabled,
});
export default {
    name: "gdc",
    mixins: [
        /**
         * @mixin
         * @name toolsHelper - mixin to control tools & monitoring actions
         */
        toolsHelper,
    ],
    components: {
        AnalysisCard,
        "neo-loader": NeoLoader,
        NeoInput,
        NeoButton,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        NeoAnalyse,
        "download-csv": JsonCSV,
        PersonList,
        PersonListExpand,
        neoSwitch,
        DatePicker,
        AccordionCard,

        DetailList,
        Identity,
        Addrs,
        Email,
        Phone,
        Details,
    },
    props: {
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        isQueryPanelExpanded: {
            type: Boolean,
            default: false,
        },
        toolSearchQueries: Array,
        entityValue: String,
        isOpen: Boolean,
        position: String,
    },
    data() {
        return {
            max: 20,
            case_id: "",
            emailInput: "",
            numberInput: "",
            fNameInput: "",
            lNameInput: "",
            mNameInput: "",
            houseInput: "",
            addHouseInput: "",
            addressL1Input: "",
            addressL2Input: "",
            throughfareInput: "",
            localityInput: "",
            postalInput: "",
            districtInput: "",
            provinceInput: "",
            gdcsourceCheck: true,
            allData: [],
            isLoading: false,
            load: false,
            nationalId: [],
            id: 0,
            usernameList: [],
            phoneList: [],
            emailList: [],
            triggerRoute: true,
            showResult: false,
            selected_value: {},
            showMoreInfo: false,
            selected_index: null,
            showSearch: true,
            selectVal: {},
            searchClicked: false,
            tabName: "",
            request_id: null,
            date: new Date().getFullYear(),
            requestId: "",
            isChecked: false,
            showForm: true,
            /**
             * @property
             * @name allSources - sources data along with sources filters and title
             *                  - each source data returned through a function generateFiltersWrapperModel
             *                  - containing source title, source name mapping, source value mapping, and filters allowed
             */
            allSources: {
                country: generateFiltersWrapperModel("Country", "country", "country", ["country", "countryCode"], "tw-bg-blue-100"),
            },
            sources: [],
            /**
             * @property
             * @name country - country filter data
             */
            country: {
                label: "Country",
                value: [],
                props: {
                    options: [],
                    groupLabel: "group",
                    groupValues: "categories",
                    groupSelect: false,
                    trackBy: "name",
                    label: "name",
                    multiple: false,
                    limit: 3,
                    checkboxes: true,
                    placeholder: "Select Country",
                    tagPlaceholder: "",
                    closeOnSelect: true,
                    showLabels: true,
                    searchable: true,
                },
                isSourceSpecific: false,
                countryName: null,
            },
            /**
             * @property
             * @name countryCode - countryCode filter data
             */
            countryCode: {
                label: "Country Code",
                value: [],
                props: {
                    options: [],
                    groupLabel: "group",
                    groupValues: "categories",
                    groupSelect: false,
                    trackBy: "value",
                    label: "value",
                    multiple: false,
                    limit: 3,
                    checkboxes: true,
                    placeholder: "Select Country Code",
                    tagPlaceholder: "",
                    closeOnSelect: true,
                    showLabels: true,
                    searchable: true,
                },
                isSourceSpecific: false,
                countryName: null,
            },
            nationalDetails: [],
            gdcBody: null,
            verifyBody: null,
            nationalvalue: [],
            SearchResult: null,
            isLoading: false,
            detailsSection: false,
            listMenu: [
                {
                    label: "Address",
                    value: "address",
                    dataExist: false,
                    reliability: "",
                },
                {
                    label: "Identity",
                    value: "identity",
                    dataExist: false,
                    reliability: "",
                },
                {
                    label: "Email",
                    value: "email",
                    dataExist: false,
                    reliability: "",
                },
                {
                    label: "Phone",
                    value: "phone",
                    dataExist: false,
                    reliability: "",
                },
                {
                    label: "Details",
                    value: "details",
                    dataExist: false,
                },
            ],
            expandAllListItems: false,
            cardDetails: [],
            errorMessage: "",
            errorMessageM: "",
            errorMessageL: "",
            errorMessageP: "",
            flag: false,
            isdisabled: false,
            publishedDate: null,
            searchFCall: false,
            searchMCall: false,
            searchLCall: false,
            searchNCall: false,
            isExpand: true,
            expandedItems: [],
            uniqueNationalId: false,
            last_runtime: "",
            companyId: [],
            companyDetails: [],
            businessIds: [],
            entities: [
                {
                    group: "Entity Type",
                    categories: [
                        {
                            text: "Person",
                            value: "person",
                        },
                        {
                            text: "Company",
                            value: "company",
                        },
                    ],
                },
            ],
            companyName: "",
            uniqueCompanyId: false,
            selectedEntity: {text: "Person", value: "person"},
        };
    },
    computed: {
        ...mapGetters(["getPermissions", "getReadOnlyMode", "getResearchData", "getAllToolsData", "getCaseUsernames", "getCaseEmails", "getCasePhones", "getSelectedToolQuery", "getCaseId", "getProduct", "getActiveCaseId", "getCaseNames", "getSocialPlatforms", "checkPermissionsFetched", "getToolsFilterData", "nerFilters"]),
        last_run_date() {
            return this.getSelectedToolQuery?.query?.last_runtime ?? this.last_runtime;
        },
        menuClasses() {
            return {
                "offcanvas-menu": this.isOpen,
                "offcanvas-menu--right": this.isOpen && this.position === "right",
            };
        },
        formattedDate() {
            let formatDate = ["Please select Date of Birth"];
            if (this.publishedDate != null && !isNaN(this.publishedDate)) {
                let start = this.publishedDate;
                start = DateTime.fromMillis(start);
                this.publishedDate = start.toFormat("yyyy/MM/dd");
                let fDate = start.toFormat("dd/MM/yyyy");
                formatDate = [fDate];
                return formatDate[0];
            } else if (this.publishedDate != null && isNaN(this.publishedDate)) {
                return this.publishedDate;
            } else {
                return formatDate[0];
            }
        },
        isExpandALl() {
            return this.listMenu.some((el) => el.dataExist);
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;

        await this.fetchAllJuridictions();
        for (let source in this.allSources) {
            this.allSources[source].filters = this.allSources[source].filters.map((filter) => this[filter]);
        }
        //
        const dataRes = this.getToolsData(this.tabName, "gdc-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        if (this.$route.query.query_id) {
            this.redirectToQuery();
        }
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "gdc-tool");
        EventBus.$off("handleDone");
    },
    // watch: {
    //     "$route.query"(to, from) {
    //         if (this.triggerRoute) {
    //             this.redirectToQuery();
    //         }
    //     },
    // },
    validations: {
        emailInput: {
            email,
        },
        fNameInput: {
            maxLength: maxLength(20),
        },
        lNameInput: {
            maxLength: maxLength(20),
        },
        mNameInput: {
            maxLength: maxLength(20),
        },
        addressL1Input: {
            maxLength: maxLength(50),
        },
        addressL2Input: {
            maxLength: maxLength(50),
        },
        mNameInput: {
            maxLength: maxLength(20),
        },
    },
    methods: {
        // getBackgroundStatus,
        // getResultsID,
        ...mapMutations(["SET_ALL_TOOLS_DATA"]),
        ...mapActions(["getAllCaseUsernames", "getAllCaseEmails", "getAllCasePhones", "getToolsStatus"]),
        onInputFName(e) {
            this.showResult = false;
            this.searchClicked = false;
            const regex = /^[a-zA-Z. ]+$/;
            if (!regex.test(e) && e.length != 0) {
                e = e.replace(/[^a-zA-Z]/g, "");
                this.errorMessage = "Please enter alphabetic characters only.";
                this.searchFCall = true;
            } else {
                this.errorMessage = "";
                this.searchFCall = false;
            }
        },
        onInputMName(e) {
            this.showResult = false;
            this.searchClicked = false;
            const regex = /^[a-zA-Z. ]+$/;
            if (!regex.test(e) && e.length != 0) {
                e = e.replace(/[^a-zA-Z]/g, "");
                this.errorMessageM = "Please enter alphabetic characters only.";
                this.searchMCall = true;
            } else {
                this.errorMessageM = "";
                this.searchMCall = false;
            }
        },
        onInputLName(e) {
            this.showResult = false;
            this.searchClicked = false;
            const regex = /^[a-zA-Z. ]+$/;
            if (!regex.test(e) && e.length != 0) {
                e = e.replace(/[^a-zA-Z]/g, "");
                this.errorMessageL = "Please enter alphabetic characters only.";
                this.searchLCall = true;
            } else {
                this.errorMessageL = "";
                this.searchLCall = false;
            }
        },
        onInputCompanyName(e) {
            this.showResult = false;
            this.searchClicked = false;
            const regex = /^[ A-Za-z0-9_@./#&+-]*$/;
            if (!regex.test(e) && e.length != 0) {
                e = e.replace(/[^a-zA-Z]/g, "");
                this.errorMessage = "Please enter alphanumeric characters only.";
                this.searchFCall = true;
            } else {
                this.errorMessage = "";
                this.searchFCall = false;
            }
        },
        onInputNumber(event) {
            this.showResult = false;
            this.searchClicked = false;
            const regex = /^[0-9* +-]+$/;
            if (!regex.test(event) && event.length) {
                event = event.replace(/[^0-9]/g, "");
                this.errorMessageP = "Please enter numerical digits only.";
                this.searchNCall = true;
            } else {
                this.errorMessageP = "";
                this.searchNCall = false;
            }
        },
        onInput() {
            this.showResult = false;
            this.searchClicked = false;
        },

        /**
         * @function
         * @name fetchAllJuridictions - get all country list and map in the country dropdown
         */

        async fetchAllJuridictions() {
            const {data} = await OsintAxios.get("gdc/countries");
            const jurisdiction = data.data.country_details
                .filter((el) => {
                    if (el.national_id_details != "" && this.selectedEntity.value === "person") {
                        return el;
                    } else if (el.business_id_details != "" && this.selectedEntity.value === "company") {
                        return el;
                    }
                })
                .map((el) => ({
                    name: el.country_name,
                    value: el.country_code,
                    nationalDetails: el.national_id_details,
                    companyDetails: el.business_id_details,
                }));
            let sortedData = jurisdiction.sort(function(a, b) {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });
            this.countriesOptions = [
                {
                    group: "Countries",
                    categories: sortedData,
                },
            ];
            this.country.props.options = this.countriesOptions;
            this.countryCode.props.options = this.countriesOptions;
        },
        async getToolData() {
            this.phoneList = await this.getDropdownData("phone");
            this.emailList = await this.getDropdownData("email");
            this.usernameList = await this.getDropdownData("username");
        },
        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },
        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },
        onChange(value) {
            if (value) {
                this.countryCode.value = value;
                this.country.value = value;
                this.nationalDetails = value.nationalDetails;
                this.companyDetails = value.companyDetails;
                // this.nationalDetails.map((ele) => {
                //     if (ele.id) {
                //         delete ele.id;
                //     }
                // });
            } else {
                this.nationalDetails = [];
                this.countryCode.value = "";
                this.companyDetails = [];
                this.country.value = "";
            }
        },
        sourceBody() {
            let national_id_details = this.nationalDetails;
            if (this.selectedEntity.value === "person") {
                this.nationalId = this.nationalDetails.filter((el) => el?.id && el.id !== "");
                let isDuplicate = false;
                if (this.nationalId.length > 1) {
                    const ids = this.nationalId.map((el) => el.id);
                    isDuplicate = ids.some((item, idx) => ids.indexOf(item) !== idx);
                }
                if (isDuplicate) {
                    this.$toast.error("National id should be unique");
                    this.uniqueNationalId = true;
                    this.isdisabled = false;
                    return;
                } else {
                    this.uniqueNationalId = false;
                }
            }
            if (this.selectedEntity.value === "company") {
                this.companyId = this.companyDetails.filter((el) => el?.id && el.id !== "");
                let isDuplicate = false;
                if (this.companyId.length > 1) {
                    const ids = this.companyId.map((el) => el.id);
                    isDuplicate = ids.some((item, idx) => ids.indexOf(item) !== idx);
                }
                if (isDuplicate) {
                    this.$toast.error("Business id should be unique");
                    this.uniqueCompanyId = true;
                    this.isdisabled = false;
                    return;
                } else {
                    this.uniqueCompanyId = false;
                }
            }

            if (!this.$v.$invalid) {
                this.gdcBody = {
                    sources: ["gdc"],
                    address: {
                        addressLine1: this.addressL1Input,
                        addressLine2: this.addressL2Input,
                        houseNumber: this.houseInput,
                        houseNumberAddition: this.addHouseInput,
                        thoroughfare: this.throughfareInput,
                        locality: this.localityInput,
                        postalCode: this.postalInput,
                        district: this.districtInput,
                        province: this.provinceInput,
                        countryCode: this.countryCode.value.value || this.countryCode.value,
                        country: this.countryCode.value.name,
                        nationalvalue: national_id_details,
                    },
                    phone: {
                        phone_number: this.numberInput,
                    },
                    email: {
                        full_email_address: this.emailInput,
                    },
                };
                if (this.selectedEntity.value === "person") {
                    this.gdcBody.identity = {
                        first_name: this.fNameInput,
                        middle_name: this.mNameInput,
                        last_name: this.lNameInput,
                        nationalid: this.nationalDetails,
                        dob: this.publishedDate,
                    };
                }
                if (this.selectedEntity.value === "company") {
                    let companyIds = [];
                    if (this.companyDetails.length > 0) {
                        this.companyDetails.map((elem) => {
                            if (elem.id) companyIds.push(elem.id);
                        });
                    } else companyIds = this.companyDetails;
                    this.gdcBody.identity = {
                        organization_name: this.companyName,
                        businessid: companyIds,
                    };
                }
            } else {
                if (this.$v.emailInput.$invalid) {
                    this.$toast.error("Please enter a valid email Id");
                } else if (this.$v.fNameInput.$invalid || this.$v.lNameInput.$invalid || this.$v.mNameInput.$invalid) {
                    this.$toast.error("The length of name input should not be more than 20 characters");
                } else if (this.$v.addressL1Input.$invalid || this.$v.addressL2Input.$invalid) {
                    this.$toast.error("The length of address input should not be more than 50 characters");
                }
            }
        },
        async sourceSearch() {
            if (this.companyName != "" || this.fNameInput != "" || this.mNameInput != "" || this.lNameInput != "" || this.addressL1Input != "" || this.addressL2Input != "" || this.districtInput != "" || this.houseInput != "" || this.addHouseInput != "" || this.throughfareInput != "" || this.localityInput != "" || this.postalInput != "" || this.provinceInput != "" || this.emailInput != "" || this.nationalId != "" || this.publishedDate != null || this.numberInput != "") {
                try {
                    let url = "";
                    if (this.selectedEntity.value === "person") url = "gdc/person/search_sources";
                    else url = "gdc/business/search_sources";
                    const {data} = await OsintAxios.post(url, this.gdcBody, {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-status": "RUNNING",
                            "x-case-id": this.case_id,
                            "X-visited": true,
                        },
                    });
                    if (data.status) {
                        this.requestId = data.request_id;
                        this.searchVerify();
                        // this.isdisabled = true;
                        this.showSearch = true;
                        this.showForm = false;
                        this.isLoading = true;
                        this.load = true;
                    } else {
                        this.$toast.error("something went wrong");
                        this.isLoading = false;
                        this.showSearch = false;
                        this.showForm = true;
                        this.isdisabled = false;
                    }
                } catch (error) {
                    this.$toast.error("something went wrong");
                    this.isLoading = false;
                    this.showSearch = false;
                    this.showForm = true;
                    this.isdisabled = false;
                    return;
                }
            } else {
                this.$toast.error("At least one field's data is required");
                this.isLoading = false;
                this.showSearch = false;
                this.showForm = true;
                this.isdisabled = false;
                return;
            }
        },
        verifyBodyFn() {
            if (!this.$v.$invalid) {
                this.verifyBody = {
                    request_id: this.requestId,
                    sources: ["gdc"],
                    saved: false,
                    monitoring_status: false,
                    address: {
                        addressLine1: this.addressL1Input,
                        addressLine2: this.addressL2Input,
                        houseNumber: this.houseInput,
                        houseNumberAddition: this.addHouseInput,
                        thoroughfare: this.throughfareInput,
                        locality: this.localityInput,
                        postalCode: this.postalInput,
                        district: this.districtInput,
                        province: this.provinceInput,
                        countryCode: this.countryCode.value.value || this.countryCode.value,
                        // country: this.country.value.value || this.country.value,
                    },
                    phone: {
                        phone_number: this.numberInput,
                    },
                    email: {
                        full_email_address: this.emailInput,
                    },
                };
                if (this.selectedEntity.value === "person") {
                    this.verifyBody.identity = {
                        first_name: this.fNameInput,
                        middle_name: this.mNameInput,
                        last_name: this.lNameInput,
                        nationalid: this.nationalDetails?.filter((el) => el.hasOwnProperty("id") && el.id !== ""),
                        dob: this.publishedDate,
                    };
                }
                if (this.selectedEntity.value === "company") {
                    let companyIds = [];
                    if (this.companyDetails.length > 0) {
                        this.companyDetails.map((elem) => {
                            if (elem.id) companyIds.push(elem.id);
                        });
                    } else companyIds = this.companyDetails;
                    this.verifyBody.identity = {
                        organization_name: this.companyName,
                        businessid: companyIds,
                    };
                }
            } else {
                if (this.$v.emailInput.$invalid) {
                    this.$toast.error("Please enter a valid email Id");
                } else if (this.$v.fNameInput.$invalid || this.$v.lNameInput.$invalid || this.$v.mNameInput.$invalid) {
                    this.$toast.error("The length of input should not be more than 20 characters");
                }
            }
        },
        async searchVerify() {
            if (this.requestId) {
                this.verifyBodyFn();
                try {
                    let url = "";
                    if (this.selectedEntity.value === "person") url = "gdc/person/verify?cache_bypass=false";
                    else url = "gdc/business/verify?cache_bypass=false";
                    const {data} = await OsintAxios.post(url, this.verifyBody, {
                        headers: {
                            "x-tool-name": this.$route.params.toolName,
                            "x-status": "RUNNING",
                            "x-case-id": this.case_id,
                            "x-refresh": false,
                            "X-visited": true,
                        },
                    });
                    if (data.data.length) {
                        this.SearchResult = data.data;
                        this.showResult = true;
                        this.isLoading = false;
                        this.load = false;
                        // this.isdisabled = true;
                        let query = {};
                        if (this.requestId)
                            query = {
                                query_id: this.requestId,
                            };
                        // this.last_run_date()
                        this.$router.push({path: this.$route.path, query: query}).catch(() => {});
                        this.$emit("refreshQueries");
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "gdc-tool"});
                        this.$emit("searchResult", {data: this.SearchResult, source: "gdc", searchType: "name"});
                    } else {
                        if (data.status_code == 2000) {
                            this.$toast.error("(2000) Request failed due to network connection issue.");
                        } else if (data.status_code == 2001) {
                            this.$toast.error("(2001) Connection refused due to invalid credentials.");
                        } else if (data.status_code == 2002) {
                            this.$toast.error("(2002) Request failed due to DNS proxy issues");
                        } else if (data.status_code == 2003) {
                            this.$toast.error("(2003) Mismatch in data returned by source");
                        } else {
                            this.$toast.error(data.message);
                        }
                        this.isLoading = false;
                        this.showSearch = false;
                        this.showForm = true;
                        this.isdisabled = false;
                    }
                } catch (error) {
                    if (error.response && error.response.status !== 406) this.$toast.error("something went wrong");
                    this.isLoading = false;
                    this.showSearch = false;
                    this.showForm = true;
                    this.isdisabled = false;
                    return;
                }
            }
        },
        async handleSearch() {
            this.isdisabled = true;
            if (this.countryCode.value.value == "" || this.countryCode.value == "") {
                this.$toast.error("Please Select a Country");
                this.isdisabled = false;
                return;
            } else {
                this.sourceBody();
                this.triggerRoute = true;
                if (this.searchNCall || this.searchFCall || this.searchLCall || this.searchMCall) {
                    this.$toast.error("Please Enter valid Input");
                    this.isdisabled = false;
                }
                if (!this.requestId && !this.$v.$invalid && !this.searchFCall && !this.searchMCall && !this.searchLCall && !this.searchNCall && !this.uniqueNationalId && !this.uniqueCompanyId) {
                    this.sourceSearch();
                }
            }
        },
        async searched() {
            this.verifyBodyFn();
            this.showForm = false;
            this.isLoading = true;
            this.load = true;
            this.showResult = false;
            if (this.requestId) {
                this.SearchResult = [];
                let url = "";
                if (this.selectedEntity.value === "person") url = "gdc/person/verify?cache_bypass=false";
                else url = "gdc/business/verify?cache_bypass=false";
                const {data} = await OsintAxios.post(url, this.verifyBody, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-status": "RUNNING",
                        "x-case-id": this.case_id,
                        "x-refresh": false,
                        "X-visited": true,
                    },
                });
                if (data.data.length) {
                    this.SearchResult = data.data;
                    this.isLoading = false;
                    this.load = false;
                    this.showResult = true;
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "gdc-tool"});
                    this.$emit("searchResult", {data: this.SearchResult, source: "gdc", searchType: "name"});
                    this.redirectTo();
                } else {
                    if (data.status_code == 2000) {
                        this.$toast.error("Request failed due to network connection issue.");
                    } else if (data.status_code == 2001) {
                        this.$toast.error("Connection refused due to invalid credentials.");
                    } else if (data.status_code == 2002) {
                        this.$toast.error("Request failed due to DNS proxy issues");
                    } else if (data.status_code == 2003) {
                        this.$toast.error("Mismatch in data retuned by source");
                    } else {
                        this.$toast.error(data.message);
                    }
                    this.isLoading = false;
                    this.showSearch = false;
                    this.showForm = true;
                    this.isdisabled = false;
                }
            } else {
                this.$toast.error("Something Went Wrong");
                this.isLoading = false;
                this.load = false;
                this.showForm = true;
                this.isdisabled = false;
            }
        },
        async refreshSearch() {
            this.searchVerify();
        },
        openEntityMenu(result) {
            this.cardDetails = result;
            this.addressData = this.cardDetails.complete_details.address;
            this.identityData = this.cardDetails.complete_details.identity;
            this.emailData = this.cardDetails.complete_details.email;
            this.phoneData = this.cardDetails.complete_details.phone;
            this.detailsData = this.cardDetails.complete_details;
            this.detailsSection = true;
            this.isDataExist();
        },
        close() {
            this.detailsSection = false;
        },
        async onBackToSearch() {
            // this.resetFilter();
            this.showResult = false;
            this.showForm = true;
            this.SearchResult = [];
            this.requestId = "";
            this.fetchAllJuridictions();
            this.countryCode.value = {
                name: this.countryCode.value.name || this.countryCode.name,
                value: this.countryCode.value.value || this.countryCode.value,
                nationalDetails: this.nationalvalue,
                companyDetails: this.businessIds,
            };
            this.country.value = {
                name: this.countryCode.value.name || this.countryCode.name,
                value: this.countryCode.value.value || this.countryCode.value,
                nationalDetails: this.nationalvalue,
                companyDetails: this.businessIds,
            };
            this.nationalId = [];
            this.companyId = [];
            // this.fetchAllJuridictions();
            // tfhis.onChange(this.country.value)
            // this.nationalDetails = this.nationalvalue;
            this.isdisabled = false;
            await this.getToolsStatus();
            this.$router.push({path: this.$route.path}).catch((err) => {
                console.error(err);
            });
            this.$forceUpdate();
        },
        resetFilter() {
            this.requestId = "";
            this.showResult = false;
            this.showForm = true;
            this.searchQuery = "";
            this.sourceType = [];
            this.companyDetails = [];
            this.nationalDetails = [];
            this.SearchResult = [];
            this.countryCode.value = [];
            this.country.value = [];
            this.triggerRoute = false;
            (this.emailInput = ""),
                (this.numberInput = ""),
                (this.fNameInput = ""),
                (this.companyName = ""),
                (this.lNameInput = ""),
                (this.mNameInput = ""),
                (this.houseInput = ""),
                (this.addHouseInput = ""),
                (this.addressL1Input = ""),
                (this.addressL2Input = ""),
                (this.throughfareInput = ""),
                (this.localityInput = ""),
                (this.postalInput = ""),
                (this.districtInput = ""),
                (this.provinceInput = ""),
                (this.gdcsourceCheck = true),
                (this.publishedDate = null),
                (this.nationalDetails = []),
                (this.nationalId = []),
                (this.isdisabled = false);
            this.searchFCall = false;
            this.searchMCall = false;
            this.searchLCall = false;
            this.searchNCall = false;
            this.errorMessageP = "";
            this.errorMessage = "";
            this.errorMessageM = "";
            this.errorMessageL = "";
            this.fetchAllJuridictions();
        },
        getStatusColor(status) {
            if (status == "20") {
                return "tw-text-yellow-500";
            }
            if (status == "30") {
                return "tw-text-red-500";
            }
            if (status == "10") {
                return "tw-text-green-500";
            }
        },
        /**
         * @function
         * @name redirectToQuery
         * @params {object} - dataRes - search filters data from the route to be autofill in the input fiels and filters
         */
        async redirectToQuery(dataRes) {
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                let {address, email, identity, phone, sources} = selected_query?.query;
                if (address || email || identity || phone || sources) {
                    let gdcBody = {
                        request_id: selected_query._id,
                        sources: ["gdc"],
                        saved: false,
                        monitoring_status: false,
                        address: {
                            addressLine1: address.addressLine1,
                            addressLine2: address.addressLine2,
                            houseNumber: address.houseNumber,
                            houseNumberAddition: address.houseNumberAddition,
                            thoroughfare: address.thoroughfare,
                            locality: address.locality,
                            postalCode: address.postalCode,
                            province: address.province,
                            countryCode: address.countryCode,
                            country: address.country,
                            nationalvalue: address.national_id_details,
                        },
                        identity: {
                            first_name: identity.first_name,
                            middle_name: identity.middle_name,
                            last_name: identity.last_name,
                            nationalid: identity.nationalid,
                            dob: identity.dob,
                        },
                        phone: {
                            phone_number: phone.phone_number,
                        },
                        email: {
                            full_email_address: email.full_email_address,
                        },
                    };
                    this.showForm = false;
                    this.isLoading = true;
                    this.load = true;
                    this.showResult = false;
                    if (selected_query._id) {
                        this.SearchResult = [];
                        try {
                            const {data} = await OsintAxios.post("gdc/person/verify?cache_bypass=false", gdcBody, {
                                headers: {
                                    "x-tool-name": this.$route.params.toolName,
                                    "x-status": "RUNNING",
                                    "x-case-id": this.case_id,
                                    "x-refresh": false,
                                    "X-visited": true,
                                },
                            });
                            this.SearchResult = data.data;
                            this.isLoading = false;
                            this.load = false;
                            this.showResult = true;
                            this.last_runtime = selected_query.last_runtime;
                            this.$emit("refreshQueries");
                        } catch (error) {
                            if (error.response && error.response.status !== 406) this.$toast.error("something went wrong");
                            this.isLoading = false;
                            this.showSearch = false;
                            this.showForm = true;
                            this.isdisabled = false;
                            return;
                        }
                    }
                } else if (this.searchedClicked) {
                    if (!dataRes) this.handleSearch();
                    else this.redirectTo();
                }
            } else {
                this.redirectTo();
            }
        },
        /**
         * @function
         * @name redirectTo - function to update input filters data to the route
         */
        redirectTo() {
            let query = {};
            if (this.requestId)
                query = {
                    query_id: this.requestId,
                };
            this.$router.push({path: this.$route.path, query: query}).catch(() => {});
        },

        getFormattedTime(ts) {
            if (ts) {
                let date = new Date(ts).toISOString();
                date = DateTime.fromISO(date);
                return date.toFormat("dd MMM yyyy");
            }
            return "-";
        },
        // handle expand all and collapse all text click
        handleExpand(type) {
            const {detailList} = this.$refs;
            const isAnyDataExist = this.listMenu.some((el) => el.dataExist);
            if (isAnyDataExist) {
                switch (type) {
                    case "expand": {
                        this.isExpand = false;
                        detailList.forEach((component) => {
                            component.showItemDetail = true;
                        });
                        break;
                    }
                    case "collapse": {
                        this.expandedItems = [];
                        this.isExpand = true;
                        detailList.forEach((component) => {
                            component.showItemDetail = false;
                        });
                        break;
                    }
                }
            }
        },
        handleExpandAll(item, flag) {
            if (flag) {
                this.expandedItems.push(item);
            } else {
                const index = this.expandedItems.indexOf(item);
                if (index > -1) {
                    this.expandedItems.splice(index, 1);
                }
            }
            const allItems = this.listMenu.map((el) => el.value);
            if (allItems.length === this.expandedItems.length) {
                this.isExpand = !this.expandedItems.every((val) => allItems.includes(val));
            } else {
                this.isExpand = true;
            }
        },
        clearPublishedDate() {
            this.publishedDate = null;
        },
        disabledAfterToday(date) {
            const today = new Date();
            return date > today;
        },
        isDataExist() {
            this.listMenu.forEach((item) => {
                item.dataExist = this.checkDataLength(item);
                item.reliability = this.relibilityValue(item);
            });
        },
        checkDataLength(item) {
            if (
                item.value === "address" &&
                (this.cardDetails.complete_details.identity.completename ||
                    this.cardDetails.complete_details.address.houseNumber ||
                    this.cardDetails.complete_details.address.houseNumberAddition ||
                    this.cardDetails.complete_details.address.thoroughfare ||
                    this.cardDetails.complete_details.address.postalCode ||
                    this.cardDetails.complete_details.address.formalCountryName ||
                    this.cardDetails.complete_details.address.addressLine1 ||
                    this.cardDetails.complete_details.address.addressLine2 ||
                    this.cardDetails.complete_details.address.locality ||
                    this.cardDetails.complete_details.address.countryName ||
                    this.cardDetails.complete_details.address.countryCode ||
                    this.cardDetails.complete_details.address.province)
            ) {
                return true;
            } else if (item.value === "identity" && (this.cardDetails.complete_details.identity.completename || this.cardDetails.complete_details.identity.organization_name || this.cardDetails.complete_details.identity.businessid || this.cardDetails.complete_details.identity.givenfullname || this.cardDetails.complete_details.identity.surname_first || this.cardDetails.complete_details.identity.dob || this.cardDetails.complete_details.identity.nationalid)) {
                return true;
            } else if (item.value === "email" && this.cardDetails.complete_details.email.full_email_address) {
                return true;
            } else if (item.value === "phone" && this.cardDetails.complete_details.phone.phone_number) {
                return true;
            } else if (item.value === "details" && this.cardDetails.complete_details) {
                return true;
            } else {
                return false;
            }
        },
        relibilityValue(item) {
            if (item.value === "address") {
                return this.cardDetails.details.address.reliability;
            } else if (item.value === "identity") {
                return this.cardDetails.details.identity.reliability;
            } else if (item.value === "email") {
                return this.cardDetails.details.email.reliability;
            } else if (item.value === "phone") {
                return this.cardDetails.details.phone.reliability;
            }
        },
    },
};
